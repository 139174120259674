.tel__pic:hover {
  opacity: 1;
}
.tel {
  display: inline-block;
  margin-top: 5px;
  font-size: 16px;
}
.tel__pic {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  opacity: 0.7;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.picPhoto {
  max-height: 40px;
  max-width: 40px;
}

.mail__container:hover {
  opacity: 1;
}
.mail__container {
  display: inline-block;
  min-width: 207px;
}
.mail {
  display: inline-block;
  font-size: 16px;
  padding: 10px 0;
  margin: 0;
}
.mail__pic {
  text-align: center;
  cursor: pointer;
  opacity: 0.7;
  display: inline-block;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.mail__pic:hover {
  opacity: 1;
}
@media screen and (max-width: 825px) {
  .mail {
    font-size: 13px;
    margin: 0;
    padding: 0;
  }
  .picPhoto {
    max-height: 30px;
    max-width: 30px;
  }
  .tel {
    display: inline-block;
    margin-top: 0;

    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .mail {
    font-size: 16px;
    margin: 0;
  }
}
@media screen and (max-width: 360px) {
  .mail {
    font-size: 13px;
    margin: 0;
  }
}
@media screen and (max-width: 330px) {
  .mail {
    font-size: 12px;
    margin: 0;
  }
}
