.footer__container {
  background-color: #004383;
  max-height: 800px;
  min-height: 150px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    ". a a a a m m m m m m"
    ". t t t t m m m m m m"
    ". h h h h m m m m m m"
    ". d d d d m m m m m m"
    ". l l l l m m m m m m";
}
.footer__avisoLegal__container {
  grid-area: l;
  text-align: left;
  color: white;
  cursor: pointer;
}

.footer__horario__container {
  grid-area: h;
  text-align: left;
  color: white;
}
.footer__direccion__container {
  grid-area: d;
  text-align: left;
  color: white;
}

.footer__tel__container {
  grid-area: t;
  display: grid;
  cursor: pointer;
  grid-template-columns: 42px auto;
  grid-template-rows: 40px;
  grid-template-areas: "l e";
}
.footer__tel__container:hover {
  opacity: 1;
}

.footer__tel {
  grid-area: e;
  margin-top: 10px;
  font-size: 16px;
}
.footer__tel__pic {
  text-align: center;
  grid-area: l;
  opacity: 0.7;

  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.footer__tel__pic:hover {
  opacity: 1;
}
.footer__picPhoto {
  max-height: 40px;
  max-width: 40px;
}
.footer__mail__container {
  grid-area: a;
  padding-top: 6px;
  display: grid;
  grid-template-columns: 42px auto;
  grid-template-rows: 45px;
  grid-template-areas: "i p";
}
.footer__mail__container:hover {
  opacity: 1;
}
.footer__mail {
  grid-area: p;
  font-size: 16px;
  padding: 10px 0;
  margin: 0;
}
.footer__mail__pic {
  text-align: center;
  cursor: pointer;
  opacity: 0.7;
  grid-area: i;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.footer__mail__pic:hover {
  opacity: 1;
}

.footer__map__container {
  grid-area: m;
}

@media screen and (max-width: 775px) {
  .footer__container {
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas:
      "a a a a m m m m m m"
      "t t t t m m m m m m"
      "h h h h m m m m m m"
      "d d d d m m m m m m"
      "l l l l m m m m m m";
  }
  .footer__mail {
    font-size: 14px;
    padding: 10px 0;
    margin: 0;
  }
}

@media screen and (max-width: 590px) {
  .footer__container {
    grid-template-areas:
      "a a t t m m m m m m"
      "h h h h m m m m m m"
      "h h h h m m m m m m"
      "d d d d m m m m m m"
      "l l l l m m m m m m";
  }
  .footer__horario__container {
    text-align: center;
  }
  .footer__mail {
    visibility: hidden;
  }
  .footer__tel__container {
    padding-top: 6px;
  }
  .footer__tel {
    visibility: hidden;
  }
  .footer__direccion__container {
    text-align: center;
  }
  .footer__avisoLegal__container {
    text-align: center;
  }
}
